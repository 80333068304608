import React from "react"
//import { FaBeer } from 'react-icons/fa';
import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageHeader, Banner } from '../utils'
import projectsImg from '../images/bg/projectsBg.jpg'

const ProjectsPage = () => (
  <Layout>
    <SEO title="Projects" keywords={[`project`, `team`, `people`]} />
    <PageHeader img={projectsImg}>
      <Banner title="Our Projects" subtitle="We have over 16 projects for you to choose from" />
    </PageHeader>

  </Layout>
)

export default ProjectsPage